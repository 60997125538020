import React from "react";
import logo from "../assets/logo.svg";

export const Header = props => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="white"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <img src={logo} alt="logo" />
                <a
                  href="https://api.whatsapp.com/send?phone=573203531904&text=Buenos%20d%C3%ADas%20Cavalleros%20%E2%9A%94%EF%B8%8F%F0%9F%9B%A1%EF%B8%8F%2C%20necesito%20una%20Cava!%20"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Pide Ya!
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
